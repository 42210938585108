:root {
  --font-family: 'Roboto', sans-serif;
  --header-text-color: #4f4b8b;
  --header-text-color-second: #a1a0b9;
  --second-background-color: #f5f4fb;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}