.title {
    text-align: center;
    margin-bottom: .5rem;
    color: var(--header-text-color);
    font-size: 34px;
    font-weight: 700;
    font-family: var(--font-family);
    margin-top: 4rem;
}

.subtitle {
    text-align: center;
    margin-bottom: .5rem;
    color: var(--header-text-color-second);
    font-size: 20px;
    font-weight: 300;
    font-family: var(--font-family);
}

.cards {
    padding: 4rem 0;
}