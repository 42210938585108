.logo {
    height: 32px;
}

.link {
    margin: 0px 20px;
    cursor: pointer;
}
.link:hover {
    color: var(--header-text-color-second);
}

.link_mobile {
    display: block;
    margin: 10px 20px;
    cursor: pointer;
}