.text {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    color: rgba(0, 0, 0, 0.6);
    text-align: justify;
}

.text > h1, h2, h3 {
    color: rgba(0, 0, 0, 0.8);
}