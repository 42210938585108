.hero {
    position: relative;
    min-height: 600px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.hero_image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}

.hero_content {
    position: absolute;
    z-index: 1;
}

.hero_title {
    color: #fff;
    font-weight: 500;
    font-size: 3rem;
    text-align: center;
    font-family: var(--font-family);
    margin: 0px;
}

.hero_text {
    color: #fff;
    font-weight: 300;
    font-size: 1.5rem;
    text-align: center;
    font-family: var(--font-family);    
}